import { Button, Form, Input, message } from "antd";
import "./style.scss";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import Api from "../../Network";
import { setActiveChat, setInstanceContacts } from "../../redux/action";
import { useDispatch } from "react-redux";
import { LoginIcon } from "../../icons/loginIcon";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

type FormValues = {
  user_name: string;
  password: string;
};

const Login = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const handleOnLogin = async (values: FormValues) => {
    const data = {
      user_name: values?.user_name,
      password: values?.password,
    };
    setLoading(true);

    try {
      const response = await Api.post("auth/login", data);
      if (response?.user?.accessToken) {
        localStorage.setItem("socketUrl", response?.socketUrl);
        localStorage.setItem("refresh-token", response?.user?.refreshToken);
        localStorage.setItem("token", response?.user?.accessToken);
        setLoading(false);
        dispatch(setActiveChat(null));
        dispatch(setInstanceContacts(null));
        history.push("/");
        window.location.reload();
        setLoading(false);
      } else {
        setLoading(false);
        message.error(`${t("general.Wrong_User_Or_Password")}`);
      }
    } catch (error) {
      console.log({ error });
      message.error(`${t("general.Wrong_User_Or_Password")}`);
      setLoading(false);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-form">
        <img src="/assets/logoColored.webp" alt="logo" />
        <p>{t("general.login")}</p>
        <Form
          layout="vertical"
          className="login-form-wrapper"
          name="login-form"
          form={form}
          onFinish={handleOnLogin}
        >
          <Form.Item
            name="user_name"
            rules={[
              {
                required: true,
                message: `${t("general.User_Name_is_required")}`,
              },
            ]}
          >
            <Input placeholder="User Name" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: `${t("general.Password_is_required")}`,
              },
            ]}
          >
            <Input.Password placeholder={t("general.Password")} />
          </Form.Item>

          <Button loading={loading} type="primary" htmlType="submit">
            {t("general.Sign_In")}
          </Button>
        </Form>
      </div>
      <div className="login-icon-container">
        <LoginIcon />
        {/* <img src="/assets/loginIcon.png" alt="Login" /> */}
      </div>
    </div>
  );
};

export default Login;
