import moment from "moment";
import { LastSeenIcon } from "../icons/LastSeenIcon";
import { MessageSentIcon } from "../icons/MessageSentIcon";
import { MessageStatus } from "../types/types";
import { ClockCircleOutlined } from "@ant-design/icons";
import { message } from "antd";
import { IMessage } from "../types/Message";
import Api from "../Network";
import { AxiosError } from "axios";
import * as XLSX from "xlsx";
import _ from "lodash";
import { getToken } from "firebase/messaging";
import { messaging } from "../fireBase";

export const getStatusIcon = (msg: any, userInfo: any) => {
  if (msg.isNewMsg) return <ClockCircleOutlined />;
  if (userInfo?.company_instance?.hasChatSeen) {
    switch (msg.status) {
      case MessageStatus.Pending:
        return <ClockCircleOutlined />;
      case MessageStatus.Send:
        return <MessageSentIcon />;
      case MessageStatus.Delivered:
        return <LastSeenIcon />;
      case MessageStatus.Read:
        return <LastSeenIcon seen />;
      default:
        return <ClockCircleOutlined />;
    }
  } else {
    switch (msg.status) {
      case MessageStatus.Pending:
        return <ClockCircleOutlined />;
      default:
        return <MessageSentIcon />;
    }
  }
};

export const playSoundAlert = (audioUrl: string) => {
  const audio = new Audio(audioUrl);
  // audio.play();

  const playPromise = audio.play();

  if (playPromise !== undefined) {
    playPromise
      .then(() => {
        // Audio started playing
      })
      .catch((error) => {
        console.error("Error playing audio:", error);
      });
  }
};

export const generateRandomData = (
  length?: number,
  limit?: number,
  moreData?: string[]
): {
  name: string | number;
  value: number;
  amt: number;
  [key: string]: any; // This allows for additional properties
}[] => {
  const mathFunc = () => Math.floor(Math.random() * (limit ?? 100));
  return Array.from({ length: length ?? 24 }, (_, i) => ({
    name: i + 1,
    value: mathFunc(),
    amt: mathFunc(),
    ...(moreData
      ? moreData.reduce((acc, label) => ({ ...acc, [label]: mathFunc() }), {})
      : {}),
  }));
};

export const convertArrayToObject = (array: any[], key: string) => {
  // if(!array.length) return {}
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const formatRelativeTime = (timeBySeconds: number) => {
  return moment.unix(timeBySeconds).calendar({
    sameDay: "[Today] - hh:mm A",
    lastDay: "[Yest] - hh:mm A",
    lastWeek: "DD MMM - hh:mm A",
    sameElse: "DD MMM - hh:mm A",
  });
};

export const formatToDuration = (timeByMilliSeconds: number) => {
  let duration = moment.duration(timeByMilliSeconds);
  return `${Math.floor(duration.asHours())}:${duration.minutes()}:${duration.seconds()}`;
};

export const handleMsgMention = (text: string, newFormat: any = "@$1") => {
  const mentionRegex = /@\(-:(.*):-\)/g;
  const stringWithMentions = text.replace(mentionRegex, newFormat);
  return stringWithMentions;
};

function shouldConcate(firstMsg: any, secondMsg: any): boolean {
  return (
    firstMsg?.type === "image" &&
    secondMsg?.type === "image" &&
    secondMsg?.fromMe === firstMsg?.fromMe && // don't concat user and contact
    secondMsg?.caption === firstMsg?.caption && // don't concat images with caption
    secondMsg?.status === firstMsg?.status && // don't concat images with different status
    secondMsg?.isDeleted === firstMsg?.isDeleted && // don't concat deleted with unDeleted
    firstMsg?.time - secondMsg?.time < 15 * 60 && // don't concat if 15 minutes passed
    ((secondMsg?.fromMe && // don't concat different users
      secondMsg?.user?._id === firstMsg?.user?._id) ||
      (!secondMsg?.fromMe && // don't concat different contacts in groups
        secondMsg?.groupContact &&
        firstMsg?.groupContact &&
        secondMsg?.groupContact?._id === firstMsg?.groupContact?._id) ||
      (!secondMsg?.fromMe && // don't concat different contacts in chats
        !secondMsg?.isGroup &&
        !firstMsg?.isGroup &&
        secondMsg?.contact &&
        firstMsg?.contact &&
        secondMsg?.contact?._id === firstMsg?.contact?._id))
  );
}

// group the images of same user or contact
export const handleConcatenateImages = (data: any) => {
  if (!data) return [];
  const resultArray = [];

  for (let i = 0; i < data?.length; i++) {
    const currentItem = data[i];

    if (shouldConcate(data?.[i], data?.[i + 1])) {
      let concatenatedItem = {
        ...currentItem,
        body: [currentItem.body],
        originalMessages: [currentItem],
        _id: currentItem._id,
      };
      let j = i + 1;

      while (j < data?.length && shouldConcate(data?.[i], data?.[j])) {
        // Concatenate the body and _id of consecutive image items
        concatenatedItem.body.push(data?.[j]?.body);
        concatenatedItem.originalMessages.push(data?.[j]);
        // concatenatedItem._id.push(data?.[j]?._id);
        j++;
      }

      // Update the type to 'images'
      concatenatedItem.type = "images";

      // Add the concatenated item to the result array
      resultArray.push(concatenatedItem);

      // Move the index to the last image item in the consecutive sequence
      i = j - 1;
    } else {
      // Add non-image items directly to the result array
      resultArray.push(currentItem);
    }
  }
  return resultArray;
};

export const isObjectEmpty = (object: {}) => {
  return JSON.stringify(object) === "{}";
};

export const handleStarMessage = async ({
  items,
  activeChat,
  userInfo,
  handleUpdateUiMessages,
  isDelete,
  unStar,
}: {
  items: IMessage[];
  activeChat: any;
  userInfo: any;
  handleUpdateUiMessages: (msg: any) => any;
  isDelete?: boolean;
  unStar?: boolean;
}) => {
  try {
    if (items.length > 50 && isDelete)
      return message.error("max message to delete in one time is 50");
    const { instance, remoteJid } = activeChat!;

    const url = isDelete ? "deleteMessage" : "makeStarMessage";

    let messagesId: string[] = [];

    items.forEach((msg) => {
      if (msg.type === "images")
        msg.originalMessages?.forEach((org) => messagesId.push(org._id));
      else messagesId.push(msg._id);
    });

    const body = {
      contactJid: remoteJid,
      instanceId: instance,
      messagesId,
      isStar: isDelete
        ? undefined
        : unStar
          ? false
          : items.length == 1
            ? !items?.[0].isStarMessage
            : true,
      company: userInfo?.company_instance?._id,
    };
    await Api.post(`message/${url}`, body);
    // no socket returned for star message
    if (!isDelete) {
      if (items.length === 1) {
        handleUpdateUiMessages({
          ...items[0],
          isStarMessage: !items[0]?.isStarMessage,
        });
      } else {
        items.forEach((item) => {
          handleUpdateUiMessages({
            ...item,
            isStarMessage: unStar
              ? false
              : items.length === 1
                ? !items?.[0].isStarMessage
                : true,
          });
        });
      }
    }

    // if contact not assigned to user, no socket returned
    if (isDelete && activeChat?.agent?._id !== userInfo?._id) {
      items.forEach((item) => {
        handleUpdateUiMessages({
          ...item,
          isDeleted: true,
        });
      });
    }
  } catch (error) {
    message.error(
      //@ts-ignore
      (error as AxiosError).response?.data?.errorMessage ||
        `failed to ${isDelete ? "delete" : "star"} the message`
    );
  }
};

export const copyMessagesToClipboard = (messages: IMessage[]) => {
  let textToCopy = "";
  messages.forEach((message, index, arr) => {
    if (arr.length > 1) {
      if (index !== 0) textToCopy += `\n`;
      textToCopy += `[${moment.unix(message.time).format("DD/MM/YYYY - hh:mm A")}] ${handleGetSenderName(message)}: `;
    }
    if (message?.type === "location") {
      let location = JSON.parse(message.body ?? "invalid value to copy");
      textToCopy += `https://www.google.com/maps?q=${location.lat},${location.long}`;
    } else {
      textToCopy += message.body ?? "invalid value to copy";
    }
  });

  navigator?.clipboard
    ?.writeText(handleMsgMention(textToCopy))
    ?.then(() => {
      //   setCopied(id);
      message.info("Copied to Clipboard");
    })
    .catch((err) => {
      message.error("Unable to copy text to clipboard");
      console.log("Unable to copy text to clipboard", err);
    });
};

export const handleGetSenderName = (sender: any) => {
  if (sender?.isBot) return "Bot";
  if (sender?.isBroadCast) return "BroadCast";
  if (sender?.isSurveyBot) return "Survey Bot";
  if (sender?.isSurveyAnswer) return "Answer Survey Bot";
  if (sender?.fromMe) return sender?.user?.name || "Phone";
  if (
    sender?.isGroup &&
    !sender?.fromMe &&
    sender?.groupContact?.name?.assignName
  )
    return sender?.groupContact?.name?.assignName;

  if (sender?.isGroup && !sender?.fromMe && sender?.groupContact?.name?.savedAs)
    return sender?.groupContact?.name?.savedAs;

  if (sender?.isGroup && !sender?.fromMe && sender?.groupContact?.remoteJid)
    return sender?.groupContact?.remoteJid?.split("@")?.[0] || "";

  if (!sender?.fromMe && sender?.contact?.name?.assignName)
    return sender?.contact?.name?.assignName;
  if (!sender?.fromMe && sender?.contact?.name?.savedAs)
    return sender?.contact?.name?.savedAs;
  if (!sender?.fromMe && sender?.contact?.remoteJid)
    return sender?.contact?.remoteJid?.split("@")?.[0] || "";

  return "Phone";
};

export const downloadFile = async (url: string | string[]) => {
  try {
    if (typeof url !== "string") {
      url.forEach((url) => downloadFile(url));
      return;
    }
    const blob = await fetch(url).then((res) => res.blob());

    // Create a blob URL
    const blobUrl = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = blobUrl;

    // const extentionsRegex =
    //   /(?<=\.)(docx?|pdf|txt|rtf|odt|md|xlsx?|csv|ods|pptx?|odp|jpe?g|png|gif|bmp|tiff|svg|mp3|wav|aac|ogg|flac|mp4|avi|mov|mkv|wmv|flv|zip|rar|tar|gz|7z|exe|bat|sh|msi|apk|html|css|js|py|java|c|cpp|rb|php|sql|db|mdb|sqlite|dll|sys|log|ini|ttf|otf|woff2?)$/;

    // const fileExtention = url.match(extentionsRegex)?.[0];

    const docName = url?.split("/");
    const fileName = docName?.[docName?.length - 1]?.slice(0, 50);
    a.download = fileName || "image";

    // Append the anchor to the body
    document.body.appendChild(a);

    // Trigger a click on the anchor to start the download
    a.click();

    // Remove the anchor from the body
    document.body.removeChild(a);

    // Clean up the blob URL
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error downloading images:", error);
    // Handle errors as needed
  }
};

const sheetFromArrayOfObjects = (data: any) => {
  const ws = XLSX.utils.json_to_sheet(data);
  return ws;
};
// Function to export worksheet to Excel file
export const exportReportsToExcel = (
  data: any,
  filename: string,
  columns: any[]
) => {
  if (!data || !data?.length) return message.info("no data to export");

  // format the item
  function handleFormatItem(columns: any, item: any): {} {
    let newItem: { [key: string]: any } = {};
    for (const column of columns) {
      if (column.children) {
        // if children then get the field of these children and them to the newItem
        newItem = {
          ...newItem,
          ...handleFormatItem(column.children, item),
        };
      } else {
        // for every column get the field and value (to handle nested data and rename field)
        const value = _.get(item, column.dataIndex);
        const title: string = column.title;

        newItem[title] = value
          ? column?.isMillisecond
            ? formatToDuration(value)
            : column?.isDateTimeStamp
              ? moment(value).format("lll")
              : column?.splitted
                ? (value as string).split(column.splitted.value)[
                    column.splitted.index
                  ]
                : value
          : 0;
      }
    }
    return newItem;
  }

  let formatedData = data.map((item: any) => handleFormatItem(columns, item));

  const wb = XLSX.utils.book_new();
  const ws = sheetFromArrayOfObjects(formatedData);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Generate Excel file and force download
  XLSX.writeFile(wb, filename ?? "agent-kpi.xlsx");
};

export const requestPermissionToken = async (prevToken?: string) => {
  const permission = Notification.permission;
  if (permission === "granted") {
    try {
      const token = await getToken(messaging, {
        vapidKey:
          "BLoyFIhZLM-iHVDe3y-BFf-kS7H92fcgmntTdKlRwgMJQpbQksc0fkQ-xpwva45Mn4TjtRRq0vL6iqofN4xLulU",
      });
      if (token) {
        if (token === prevToken) return;
        Api.post("user/updateFcmToken", { fcmToken: token });
      }
    } catch (error) {
      console.error("An error occurred while retrieving token. ", error);
    }
  } else {
    console.log("Notification permission not granted.");
  }
};
