import { Button, Modal, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Api from "../../Network";
import { useSelector } from "react-redux";
import { getToken } from "firebase/messaging";
import { messaging } from "../../fireBase";
import { useTranslation } from "react-i18next";
import { requestPermissionToken } from "../../Utilis/helpers";

export const NotificationAlert = () => {
  const { userInfo } = useSelector((state: any) => state.setReducer);
  const [show, setShow] = useState(false);
  const [requireUpdatePermissions, setRequireUpdatePermissions] =
    useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setShow(Notification.permission !== "granted");
  }, []);

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      setShow(false);
      setRequireUpdatePermissions(false);
      requestPermissionToken(userInfo?.fcmToken);
    } else {
      setRequireUpdatePermissions(true);
      console.log("Notification permission not granted.");
    }
  };

  if (!show && !userInfo) return null;
  return (
    <Modal
      className="notification-modal mini-form-modal"
      title={t("NotificationAlert.title")}
      open={show}
      onCancel={() => setShow(false)}
      footer={null}
      destroyOnClose={true}
    >
      {requireUpdatePermissions && (
        <p style={{ fontSize: "1.2rem" }}>
          {t("NotificationAlert.requireUpdatePermissions")}
        </p>
      )}
      <div className="modal-btns">
        <Button
          className="modal-btn done"
          type="primary"
          onClick={() => requestPermission()}
        >
          ok
        </Button>

        <Button
          type="default"
          className="modal-btn cancel"
          onClick={() => {
            setShow(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
