import { Select, SelectProps, Spin } from "antd";
import { Dispatch, useEffect, useRef, useState } from "react";
import { useList } from "../../Hooks/configration/useList";
import _ from "lodash";

type Props = {
  queryKey: string;
  openNewModal?: Dispatch<any>;
  initialOptions?: any[];
  loading?: boolean;
  valueField?: string;
  outerFilters?: { [key: string]: any };
} & SelectProps;

export const InfiniteSelect = ({
  queryKey,
  openNewModal,
  initialOptions,
  loading,
  valueField,
  outerFilters,
  ...antSelectProps
}: Props) => {
  const {
    filters: listFilters,
    setListFilters: setListFilters,
    data: listData,
    isLoading: listLoading,
  } = useList(queryKey, {
    outerFilters: { ...outerFilters, isDeleted: false },
  });
  const [totalList, setTotalList] = useState<any[]>([]);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) setTotalList(() => []);
    else isFirstRender.current = false;

    setListFilters("filters", {
      ...listFilters.filters,
      ...outerFilters,
    });
  }, [outerFilters]);

  useEffect(() => {
    if (!listData?.data) return;
    setTotalList((prevList) => [...prevList, ...listData?.data]);
  }, [listData?.data, outerFilters]);

  // to handle the initial value that it's labels not fetched yet
  useEffect(() => {
    if (!initialOptions || !initialOptions.length) return;
    setTotalList((prevList) => [...initialOptions, ...prevList]);
  }, [initialOptions]);

  return (
    <Select
      mode="multiple"
      options={_.uniqBy(
        totalList?.map((item) => ({
          label:
            item?.nickName ||
            item?.nameAr ||
            item?.nameEn ||
            item?.name ||
            item?.filename ||
            item?.title,
          value: valueField ? item[valueField] : item?._id,
          item,
        })),
        "value"
      )}
      filterOption={() => true}
      onSearch={(search) => {
        search.length > 2 && setTotalList(() => []);
        setListFilters("filters.search", search.length > 2 ? search : "");
      }}
      onSelect={() => setListFilters("filters.search", "")}
      showSearch
      onPopupScroll={async (e: any) => {
        const { target } = e;
        if (
          (target as any).scrollTop + (target as any).offsetHeight >=
            (target as any).scrollHeight - 10 &&
          !listLoading &&
          listData?.count &&
          totalList?.length < listData?.count
        ) {
          setListFilters("paging.page", listFilters.paging.page + 1);
        }
      }}
      loading={listLoading || loading}
      suffixIcon={listLoading || loading ? <Spin /> : undefined}
      notFoundContent={listLoading ? <Spin /> : null}
      dropdownRender={(menu) => (
        <>
          {menu}
          {listLoading && !!totalList.length && <Spin />}
          {openNewModal && (
            <div
              className="ant-select-item add-new"
              onClick={() => openNewModal({})}
            >
              add new {queryKey}
            </div>
          )}
        </>
      )}
      {...antSelectProps}
    />
  );
};
