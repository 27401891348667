import { useEffect, useState } from "react";
import { listFilters } from "../../types/types";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import Api from "../../Network";
import _ from "lodash";

type useListOptions = {
  useQueryOptions?: UseQueryOptions<any>;
  outerFilters?: { [key: string]: any };
};

export function useList(key?: string, useListOptions?: useListOptions) {
  const [filters, setFilters] = useState<listFilters>({
    filters: { search: "", ...useListOptions?.outerFilters },
    paging: { page: 1, pageSize: 20 },
    sorting: {},
  });

  function setListFilters(path: string, value: any) {
    setFilters((filters) => {
      let newFitlers = { ...filters };
      _.set(newFitlers, path, value);
      return newFitlers;
    });
  }

  useEffect(() => {
    setListFilters("paging", { page: 1, pageSize: 20 });
  }, [JSON.stringify(filters.filters)]);
  return {
    filters,
    setListFilters,
    ...useQuery<{
      data: any[];
      count: number;
    }>({
      queryKey: [key, filters],
      queryFn: ({ signal }) => Api.post(`${key}`, filters, { signal }),
      enabled: !!key, // some time there will be no key (e.g. in table)

      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      ...useListOptions?.useQueryOptions,
    }),
  };
}

export const useDelete = (key: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => Api.delete(`${key}/delete?id=${id}`),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [key] }),
  });
};

export const useRestore = (key: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => Api.put(`${key}/restore?id=${id}`, {}),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [key] }),
  });
};

export const useCreate = (key: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => Api.post(`${key}/create`, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [key] }),
  });
};

export const useUpdate = (key: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: any }) =>
      Api.put(`${key}/update?id=${id}`, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [key] }),
  });
};

export const use2KeyCreate = (key: string, updatedKey: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => Api.post(`${key}/create`, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [updatedKey] }),
  });
};

export const use2KeyUpdate = (key: string, updatedKey: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: any }) =>
      Api.put(`${key}/update?id=${id}`, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [updatedKey] }),
  });
};
