import { useEffect, useState } from "react";
import { Table } from "../../../Component/Table";
import { Dropdown, Form, message, Switch } from "antd";
import { DeleteIcon } from "../../../icons/DeleteIcon";
import { EditIcon } from "../../../icons/EditIcon";
import moment from "moment";
import Api from "../../../Network";
import { talbeQueryKeys } from "../../../Hooks/configration/tableQueryKeys";
import { useDelete, useUpdate } from "../../../Hooks/configration/useList";
import { TableEditDelete } from "../../../Component/TableEditDelete/TableEditDelete";
import { BroadcastDropdownMenu } from "../../bulk/Broadcast/BroadcastDropdownMenu";
import { UserTableActions } from "../../../Component/UserTableActions/UserTableActions";
import { MoreOutlined } from "@ant-design/icons";
import { AllBotsTableActions } from "../../../Component/AllBotTableActions/AllBotTableActions";
import { Bot } from "../../../types/Bot";
import { BotOptionType } from "../../../types/BotOptionType";
import { NameByLang } from "../../../Component/NameByLang/NameByLang";
import { useTranslation } from "react-i18next";

const AllBots = () => {
  const [openBotModal, setOpenBotModal] = useState<Bot>();
  const {
    mutateAsync: updateBot,
    isPending: botLoading,
    variables,
  } = useUpdate(talbeQueryKeys.bot);

  let { t } = useTranslation();

  const toggleBotStatus = async (id: string, status: boolean) => {
    try {
      await updateBot({ id, data: { isActive: status } });
      message.success(
        `${status ? t("AllBot.toggleBotStatusSuccessActive") : t("AllBot.toggleBotStatusSuccessInActive")}`
      );
    } catch (error) {
      message.error(
        `${status ? "AllBot.toggleBotStatusFailedActive" : "AllBot.toggleBotStatusFailedInActive"}`
      );
    }
  };

  return (
    <div className="allBots-configuration">
      <Table
        tableQueryKey={talbeQueryKeys.bot}
        tableTitle={t("AllBot.title")}
        onClickNewButton={() => setOpenBotModal(undefined)}
        rowClassName={(bots) => (bots.isDeleted ? "deleted" : "")}
        additionalFilters={
          <>
            <Form.Item name="isDeleted" label="isDeleted">
              <Switch />
            </Form.Item>
            {/* add your filters here with like above "form item with the name of the field you want to send as filter" */}
          </>
        }
        columns={[
          {
            title: t("AllBot.columnState"),
            dataIndex: "isActive",
            sorter: true,
            render: (active, bots) => (
              <>
                <Switch
                  className="rect-switch"
                  checked={active}
                  loading={botLoading && variables.id == bots._id}
                  disabled={bots.isDeleted}
                  onChange={(checked, event) => {
                    toggleBotStatus(bots._id, checked);
                  }}
                />
                <p>
                  {active
                    ? t("AllBot.columnStateSwitchActive")
                    : t("AllBot.columnStateSwitchInActive")}
                </p>
              </>
            ),
          },
          {
            title: t("AllBot.columnName"),
            dataIndex: "title",
            sorter: true,
            render: (name, bots) =>
              name ? name : !/[a-z]/i.test(bots.title) ? bots.title : "-",
          },
          {
            title: t("AllBot.columnReplayMessage"),
            dataIndex: "reply",
            sorter: true,
            render: (reply, bots) =>
              reply ? reply : !/[a-z]/i.test(bots.reply) ? bots.reply : "-",
          },
          {
            title: t("AllBot.columnChannel"),
            dataIndex: ["channel"],
            sorter: true,
            render: (channel, bots) => <NameByLang item={channel} />,
          },
          {
            title: t("AllBot.columnBotTypes"),
            dataIndex: "optionsType",
            sorter: true,
            render: (type, bots) => {
              switch (type) {
                case BotOptionType.OPTIONS:
                  return t("AllBot.columnBotTypesOption");
                case BotOptionType.BOTTONS:
                  return t("AllBot.columnBotTypesButton");
                case BotOptionType.LIST:
                  return t("AllBot.columnBotTypesList");
                default:
                  return "-";
              }
            },
          },
          {
            title: t("AllBot.columnCreateDate"),
            dataIndex: "createdAt",
            sorter: true,
            render: (createdAt) =>
              createdAt ? moment(createdAt).format("YYYY-MM-DD") : "-",
          },
          {
            title: t("AllBot.columnActions"),
            render: (_, bots) => {
              return (
                <p className="actions-cell">
                  <Dropdown
                    destroyPopupOnHide={true}
                    className="msg-actions"
                    trigger={["click"]}
                    overlay={<AllBotsTableActions bot={bots} />}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </p>
              );
            },
          },
        ]}
      />
      {/*{openBotModal && (

      )}*/}
    </div>
  );
};

export default AllBots;
