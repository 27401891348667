import { Switch } from "react-router-dom";
import { PrivateRoute, PublicRoute } from ".";
import { useSelector } from "react-redux";
import { lazy } from "react";
import AiAssistants from "../Pages/AddOns/AiAssistants/AiAssistants";
import AllBots from "../Pages/Bot/All-Bots";

const ChatPage = lazy(() => import("../Pages/ChatPage"));
const ChatSetting = lazy(() => import("../Pages/ChatSetting"));
const Users = lazy(() => import("../Pages/configuration/Users/Users"));
const UserForm = lazy(
  () => import("../Pages/configuration/Users/UserForm/UserForm")
);
const Groups = lazy(() => import("../Pages/configuration/Groups/Groups"));
const Channels = lazy(() => import("../Pages/configuration/Channels/Channels"));
const Instances = lazy(
  () => import("../Pages/configuration/Instances/Instances")
);
const InstanceForm = lazy(
  () => import("../Pages/configuration/Instances/InstanceForm/InstanceForm")
);
const Stores = lazy(() => import("../Pages/configuration/Stores/Stores"));
const StoreForm = lazy(
  () => import("../Pages/configuration/Stores/StoreForm/StoreForm")
);

const Branches = lazy(() => import("../Pages/MiniCrm/Branches/Branches"));
const InquiryTypes = lazy(
  () => import("../Pages/MiniCrm/InquiryType/InquiryType")
);
const ChatReason = lazy(
  () => import("../Pages/configuration/ChatReason/ChatReason")
);
const BulkMessages = lazy(
  () => import("../Pages/bulk/BulkMessages/BulkMessages")
);
const BulkMessageContacts = lazy(
  () => import("../Pages/bulk/BulkMessages/Contacts/contacts")
);
const BulkMessageReport = lazy(
  () => import("../Pages/bulk/BulkMessages/Report/Report")
);

const BulkMessagesForm = lazy(
  () => import("../Pages/bulk/BulkMessageForm/BulkMessagesForm")
);
const TagsPage = lazy(() => import("../Pages/configuration/TagsPage/TagsPage"));
const FilesPage = lazy(() => import("../Pages/configuration/Files/Files"));
const ActivityPage = lazy(
  () => import("../Pages/configuration/Activity/Activity")
);
const ComplainTypes = lazy(
  () => import("../Pages/MiniCrm/ComplainType/ComplainType")
);
const Broadcast = lazy(() => import("../Pages/bulk/Broadcast/Broadcast"));
const BroadcastContacts = lazy(
  () => import("../Pages/bulk/Broadcast/Contacts/contacts")
);
const BroadcastReport = lazy(
  () => import("../Pages/bulk/BulkMessages/Report/Report")
);

const SpecialBroadcast = lazy(
  () => import("../Pages/bulk/Broadcast/SpecialBroadcast")
);
const Dashboard = lazy(() => import("../Pages/report/Dashboard/Dashboard"));
const AgentReports = lazy(
  () => import("../Pages/report/AgentsReports/AgentReports")
);
const BroadcastForm = lazy(
  () => import("../Pages/bulk/BroadcastForm/BroadcastForm")
);
const CustomersReports = lazy(
  () => import("../Pages/report/CustomersReports/CustomersReports")
);
const ExportReports = lazy(
  () => import("../Pages/report/ExportReports/ExportReports")
);
const HangingChat = lazy(
  () => import("../Pages/report/HangingChat/HangingChat")
);
const LastClientMessage = lazy(
  () => import("../Pages/report/LastClientMessage/LastClientMessage")
);
const DoneChats = lazy(() => import("../Pages/Setting/DoneChats/DoneChats"));

const SyncChats = lazy(() => import("../Pages/Setting/SyncChats/SyncChats"));
const allCompanies = lazy(() => import("../Pages/Setting/AllCompanies/index"));
const CompanyForm = lazy(
  () => import("../Pages/Setting/CreateCompany/CreateCompany")
);

export const RouterWrapper = () => {
  const isAuthenticated = localStorage.getItem("token");
  const { userInfo } = useSelector((state: any) => state.setReducer);

  return (
    <Switch>
      <PrivateRoute
        isAuthenticated={!!isAuthenticated}
        path="/"
        exact={true}
        component={ChatPage}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated}
        path="/phone/:phone/:messageBody"
        component={ChatPage}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.showMyChatSettings}
        path="/chat-setting"
        component={ChatSetting}
      />

      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/bot/all"
        component={AllBots}
      />

      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/bulk/messages/new"
        component={BulkMessagesForm}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/bulk/messages"
        component={BulkMessages}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/bulk/contact/:id"
        component={BulkMessageContacts}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/bulk/report/:broadCastId"
        component={BulkMessageReport}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/users"
        component={Users}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/user-form"
        component={UserForm}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/groups"
        component={Groups}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/chat-reason"
        component={ChatReason}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/channels"
        component={Channels}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/instances"
        component={Instances}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/stores"
        component={Stores}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/store-form"
        component={StoreForm}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/instance-form"
        component={InstanceForm}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/Branches/Branches"
        component={Branches}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/tags"
        component={TagsPage}
      />

      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/files"
        component={FilesPage}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/configuration/activity"
        component={ActivityPage}
      />

      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/report/dashboard"
        component={Dashboard}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/report/agents-reports"
        component={AgentReports}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/report/customers-reports"
        component={CustomersReports}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/report/export-reports"
        component={ExportReports}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/report/hanging-chat"
        component={HangingChat}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/report/last-client-message"
        component={LastClientMessage}
      />

      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/broadcast/messages/new"
        component={BroadcastForm}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/broadcast/messages"
        component={Broadcast}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/broadcast/contact/:id"
        component={BroadcastContacts}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/broadcast/report/:id"
        component={BroadcastReport}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/special/broadcast/messages/new"
        component={BroadcastForm}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/special/broadcast"
        component={SpecialBroadcast}
      />

      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/InquiryType/InquiryType"
        component={InquiryTypes}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/ComplainTypes/ComplainTypes"
        component={ComplainTypes}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/setting/done_chats"
        component={DoneChats}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/setting/sync_chats"
        component={SyncChats}
      />

      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/setting/all_companies"
        component={allCompanies}
      />
      <PrivateRoute
        isAuthenticated={!!isAuthenticated && userInfo?.canLoginDashboard}
        path="/setting/company-form"
        component={CompanyForm}
      />

      <PrivateRoute
        isAuthenticated={
          !!isAuthenticated &&
          userInfo?.canLoginDashboard &&
          userInfo?.company_instance.hasOpenAi
        }
        path="/add-ons/ai-assistants"
        component={AiAssistants}
      />
    </Switch>
  );
};
